
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form } from "reactstrap";

// Import Footer link
import FooterLinks from "./footer-links";
import Switcher from "./Switcher";

const Footer = () => {
  const [state] = useState({
    links: [
      {
        title: "Rising girls",
        child: [
          { link: "#home", title: "Home" },
          { link: "#team", title: "Products" },
          { link: "#blog", title: "Blog" },
          { link: "#contact", title: "Contact us" },
        ],
      },
      {
        title: "Information",
        child: [
          { title: "Terms & Condition" },
          { title: "About us" },
          { title: "Jobs" },
          { title: "Bookmarks" },
        ],
      },
      {
        title: "Support",
        child: [
          { title: "FAQ" },
          { title: "Contact" },
          { title: "Discussion" },
        ],
      },
    ],
  });

  return (
    <React.Fragment>
      <footer className="footer">
        <Container>
          <Row>
            {state.links.map((fLink, key) => (
              <Col key={key} lg={3} className="mt-4">
                <h4>{fLink.title}</h4>
                <div className="text-muted mt-4">
                  <ul className="list-unstyled footer-list">
                    {fLink.child.map((fLinkChild, key) => (
                      <li key={key}>
                        <a href={fLinkChild.link}>{fLinkChild.title}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            ))}

            <Col lg={3} className="mt-4">
              <h4>Subscribe</h4>
              <div className="text-muted mt-4">
                {/* <p>
                  In an ideal world, this text wouldn’t exist, a client would
                  acknowledge the importance of having web copy before the
                  design starts.
                </p> */}
              </div>
              <Form className="subscribe">
                <input placeholder="Email" className="form-control" required />
                <a href="#" className="submit">
                  <i className="pe-7s-paper-plane"></i>
                </a>
              </Form>
            </Col>
          </Row>
        </Container>
      </footer>
      {/* Render footer links */}
      <FooterLinks />

      {/* Color switcher */}
      <Switcher />
    </React.Fragment>
  );
};

export default Footer;