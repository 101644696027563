import React, { useState } from "react";
import { Container, Row } from "reactstrap";
import SectionTitle from "../common/section-title";
import ProductBox from "./ProductBox";
import Features from "./Features";

const Clients = () => {
  const [selectedClientIndex, setSelectedClientIndex] = useState(null);

  const clients = [
    {
      image: "assets/images/products/red3.png",
      title: "Ecooter",
      content: ({ onCloseButtonClick, scrollToProducts }) => (
        <Features onCloseButtonClick={onCloseButtonClick} scrollToProducts={scrollToProducts} />
      ),
    },
    // Add other clients here
  ];

  const openClientContent = (index) => {
    setSelectedClientIndex(index);
  };
  const scrollToProducts = () => {
    const productsSection = document.getElementById("team"); // Replace "products" with the actual ID of your "Products" section
    if (productsSection) {
      productsSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <React.Fragment>
      <section className="section pt-4" id="team">
        <Container>
          <SectionTitle title="Products" />
          <Row className="justify-content-center">
            <ProductBox
              clients={clients}
              openClientContent={openClientContent}
              selectedClientIndex={selectedClientIndex}
            />
          </Row>
        </Container>
      </section>
       {selectedClientIndex !== null && (
        <div className="full-content">
          {clients[selectedClientIndex].content({
            onCloseButtonClick: () => setSelectedClientIndex(null),
            scrollToProducts,
          })}
        </div>
      )}
    </React.Fragment>
  );
};

export default Clients;
