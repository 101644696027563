import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const ImageSlider = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const imageSlideStyles = `
  .image-slider-container .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-slider-container .slick-list {
    overflow: hidden;
  }

  .image-slider-container img {
    width: 100%;
    height: auto;
  }

  /* Set width for large screens */
  @media (min-width: 1200px) {
    .image-slider-container img {
      width: 600px;
    }
  }

  /* Adjust the arrow styles */
  .slick-prev, .slick-next {
    font-size: 24px;
    color: #000;
    z-index: 1; 
  }
  .slick-prev {
    left: 20px; 
  }

  .slick-next {
    right: 20px; 
  }

  .slick-prev:hover, .slick-next:hover {
    color: #000; 
  }
`;

  return (
    <div className="image-slider-container">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </Slider>
      <style>{imageSlideStyles}</style>
    </div>
  );
};

const CustomPrevArrow = (props) => (
  <div className="slick-arrow slick-prev" {...props}>
    <FaArrowLeft />
  </div>
);

const CustomNextArrow = (props) => (
  <div className="slick-arrow slick-next" {...props}>
    <FaArrowRight />
  </div>
);

export default ImageSlider;
