import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Importing Modal
import ModalSection from "../../components/common/ModalSection";

//text rotator
import ReactTextRotator from "react-text-rotator";

const content = [
  {
    text: "Professional Landing Page Template",
    className: "classA",
    animation: "fade",
  },
  {
    text: "We help startups launch their products",
    className: "classB",
    animation: "fade",
  },
  {
    text: "We shall fight in the fields and in the streets.",
    className: "classC",
    animation: "fade",
  },
  {
    text: "Perfect solution for small businesses",
    className: "classD",
    animation: "fade",
  },
];

const TextRotator = () => (
  <ReactTextRotator content={content} time={3000} startDelay={1000} />
);

const Section = () => {
  // const [state, setState] = useState({
  //   isOpen: false,
  // });

  // const callModal = () => {
  //   setState({ isOpen: true });
  // };

  // const closeModal = () => {
  //   setState({ isOpen: false });
  // };

  return (
    <React.Fragment>
      <section
        className="section section-lg vh-100"
        id="home"
        style={{
          backgroundImage: `url(assets/images/img-1.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="bg-overlay"></div>
        <div className="display-table">
          <div className="display-table-cell">
            <Container>
              <Row>
                <Col
                  lg={{ size: 8, offset: 2 }}
                  className="text-white text-center"
                >
                  <h1 className="home-title text-rotate">
                    <TextRotator />
                  </h1>
                  <p className="pt-3 home-desc">
                    WELCOME TO THE FUTURE OF URBAN TRANSPORTATION WITH SAK EVS & TECHNOLOGIES' ELECTRIC SCOOTERS.
                  </p>
                   <p className="play-shadow  mt-4">
              {/*      <Link
                      to="#"
                      onClick={callModal}
                      className="play-btn video-play-icon"
                    >
                      <i className="mdi mdi-play text-center"></i>
                    </Link>*/}
                  </p> 
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        {/* Render ModalSection Component for Modal */}
        {/* <ModalSection
          channel="vimeo"
          videoId="99025203"
          closeModal={closeModal}
          isOpen={state.isOpen}
        /> */}
      </section>
    </React.Fragment>
  );
};

export default Section;
