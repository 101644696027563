import React from "react";
import { Container, Row, Col, Form, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";

const Section = () => {
  return (
    <React.Fragment>
      <section
        className="section bg-home home-half home-registration"
        id="home"
      >
        <div className="bg-overlay"></div>
        <div className="display-table">
          <div className="display-table-cell">
            <Container>
              <Row className="vertical-content">
                <Col lg={7} className="text-white text-left mt-4">
                <h1 className="home-title">
                    EMPOWER MOVE EMPOWER YOU.
                  </h1>
                  <p className="pt-3 home-desc">
                    WELCOME TO THE FUTURE OF URBAN TRANSPORTATION WITH SAK EVS & TECHNOLOGIES' ELECTRIC SCOOTERS.
                  </p>
                  {/* <Link
                    to="#"
                    className="btn btn-primary mt-4 waves-effect waves-light"
                  >
                    Get Started <i className="mdi mdi-arrow-right"></i>
                  </Link> */}
                </Col>
                {/* <Col lg={{ size: 4, offset: 1 }} className="mt-4">
                  <div className="home-registration-form bg-white">
                    <h4 className="form-heading text-center mt-2">
                      Get 30 day FREE Trial
                    </h4>
                    <Form className="registration-form" id="registration-form">
                      <Input
                        type="text"
                        id="exampleInputName1"
                        className="form-control registration-input-box"
                        placeholder="Name"
                      />
                      <Input
                        type="email"
                        id="exampleInputEmail1"
                        className="form-control registration-input-box"
                        placeholder="Email"
                      />
                      <textarea
                        className="form-control registration-textarea-box"
                        rows="4"
                        placeholder="Message"
                      ></textarea>
                      <Button
                        color="none"
                        className="btn-primary w-100 home-btn-width waves-effect waves-light"
                      >
                        Send Detail
                      </Button>
                    </Form>
                  </div>
                </Col> */}
              </Row>
            </Container>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Section;
