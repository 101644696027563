import React, { useState } from 'react';

import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from './section';
import Service from "../../components/Specification/Specifications";
import AboutUs from "../../components/AboutUs/AboutUs";
// import WebsiteDesc from "../../components/WebsiteDesc/WebsiteDesc";
// import Pricing from "../../components/Pricing/pricing";
import Product from "../../components/Product/Product";
// import Process from "../../components/Process/Process";
// import Testimonials from "../../components/Testimonials/Testimonials"
// import GetStart from "../../components/GetStart/GetStart"
import Blog from "../../components/Blog/Blog"
import Contact from "../../components/Contact/Contact";
import Social from "../../components/Social/Social";
import Footer from "../../components/Footer/footer";

const Index7 = () => {
    const [state] = useState({
        navItems : [
            { id: 1 , idnm : "home", navheading: "Home" },
            { id: 2 , idnm : "services", navheading: "Specification" },
            { id: 3 , idnm : "features", navheading: "Sustainability" },
            // { id: 4 , idnm : "pricing", navheading: "Pricing" },
            { id: 5 , idnm : "team", navheading: "Product" },
            { id: 6 , idnm : "blog", navheading: "Blog" },
            { id: 7 , idnm : "contact", navheading: "Contact" }

        ],
        navClass : ""
    })

    
        return (
            <React.Fragment>

                {/* Importing Navbar */}
                <NavbarPage navItems={state.navItems} navClass={state.navClass} />

                {/* section */}
                <Section/>

                {/* services */}
                <Service  sectionClass="pt-5"/>

                {/* about us */}
                <AboutUs/>
                
                {/* website description */}
                {/* <WebsiteDesc/> */}

                {/* pricing */}
                {/* <Pricing/> */}

                {/* team */}
                <Product/>

                {/* process */}
                {/* <Process/> */}

                {/* testimonial */}
                {/* <Testimonials/> */}

                {/* get started */}
                {/* <GetStart/> */}

                {/* blog */}
                <Blog/>

                {/* contact */}
                <Contact/>

                {/* social */}
                <Social />

                {/* footer */}
                <Footer/>
            </React.Fragment>
        );
    }


export default Index7;