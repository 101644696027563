import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import ImageSlider from "./ImageSlider"; // Import the ImageSlider component

const featureData = {
  white: {
    name: "Ecooter",
    price: "",
    power: "Motor: 800w",
    power2: (
      <>
        Battery: 60vV 20-25AH 
        <ul>
          <li>Lead Acid</li>
          <li>Graphene</li>
          <li>Lithium</li>
        </ul>
      </>
    ),
    power3: "Speed: 55Km/h",
    power4: "Breaks: Front Disk and Rear Drum Break",
    power5: "Milage: 50-60 KM/per Charge",
    power6: "Charging cost: 1-1.5 Units",
    power7: "06 Month Warranty",
    power8: "Use as 49CC Motorcycle",
    description:
      "Electric scooters are the future of urban transportation, offering a fast, convenient, and eco-friendly way to get around town. These compact and lightweight vehicles are powered by electric motors that provide a smooth and effortless ride, making them ideal for commuting, running errands, or just cruising around.",
    bikeImage1: "assets/images/products/white1.png",
    bikeImage2: "assets/images/products/white2.png",
    bikeImage3: "assets/images/products/white3.png",
    bikeImage4: "assets/images/products/white4.png",
  },
  red: {
    name: "Ecooter",
    price: "",
    power: "Motor: 800w",
    power2: (
      <>
        Battery: 60vV 20-25AH 
        <ul>
          <li>Lead Acid</li>
          <li>Graphene</li>
          <li>Lithium</li>
        </ul>
      </>
    ),
    power3: "Speed: 55Km/h",
    power4: "Breaks: Front Disk and Rear Drum Break",
    power5: "Milage: 50-60 KM/per Charge",
    power6: "Charging cost: 1-1.5 Units",
    power7: "06 Month Warranty",
    power8: "Use as 49CC Motorcycle",
    description:
      "Electric scooters are the future of urban transportation, offering a fast, convenient, and eco-friendly way to get around town. These compact and lightweight vehicles are powered by electric motors that provide a smooth and effortless ride, making them ideal for commuting, running errands, or just cruising around.",
    bikeImage1: "assets/images/products/red3.png",
    bikeImage2: "assets/images/products/red2.png",
    bikeImage3: "assets/images/products/red1.png",
    bikeImage4: "assets/images/products/red4.png",
  },
  black: {
    name: "Ecooter",
    price: "",
    power: "Motor: 800w",
    power2: (
      <>
        Battery: 60vV 20-25AH
        <ul>
          <li>Lead Acid</li>
          <li>Graphene</li>
          <li>Lithium</li>
        </ul>
      </>
    ),
    power3: "Speed: 55Km/h",
    power4: "Breaks: Front Disk and Rear Drum Break",
    power5: "Milage: 50-60 KM/per Charge",
    power6: "Charging cost: 1-1.5 Units",
    power7: "06 Month Warranty",
    power8: "Use as 49CC Motorcycle",
    description:
      "Electric scooters are the future of urban transportation, offering a fast, convenient, and eco-friendly way to get around town. These compact and lightweight vehicles are powered by electric motors that provide a smooth and effortless ride, making them ideal for commuting, running errands, or just cruising around.",
    bikeImage1: "assets/images/products/black1.png",
    bikeImage2: "assets/images/products/black2.png",
    bikeImage3: "assets/images/products/black3.png",
    bikeImage4: "assets/images/products/black4.png",
  },
};

const Features = ({ onCloseButtonClick, scrollToProducts }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  const [selectedColor, setSelectedColor] = useState("red");
  const [modalOpen, setModalOpen] = useState(true); // Track modal state

  const handleColorButtonClick = (color) => {
    setSelectedColor(color);
  };

  const selectedFeature = featureData[selectedColor];

  const closeModal = () => {
    setModalOpen(false);
    onCloseButtonClick();
    scrollToProducts();
  };

  return (
    <Modal isOpen={modalOpen} toggle={closeModal} className="mx-auto">
      <ModalHeader toggle={closeModal}>
        <h1 className="text-center mb-4">Features</h1>
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-lg-6">
            <ImageSlider
              images={[
                selectedFeature.bikeImage1,
                selectedFeature.bikeImage2,
                selectedFeature.bikeImage3,
                selectedFeature.bikeImage4,
              ]}
             />
          </div>
          <div className="col-lg-6 my-5 bg">
            <h2>{selectedFeature.name}</h2>
            <h5>{selectedFeature.price}</h5>
            <h4>Description :</h4>
            <p className="card-text" style={{ textAlign: "justify", marginRight: "1rem" }}>
              {selectedFeature.description}
            </p>
            <h4>Features :</h4>
            <ul>
              <li>{selectedFeature.power}</li>
              <li>{selectedFeature.power2}</li>
              <li>{selectedFeature.power3}</li>
              <li>{selectedFeature.power4}</li>
              <li>{selectedFeature.power5}</li>
              <li>{selectedFeature.power6}</li>
              <li>{selectedFeature.power7}</li>
              <li>{selectedFeature.power8}</li>
            </ul>
            <h4 className="my-3">Colors :</h4>
            <button
              className={`btn btn-outline-dark btn-sm mx-2 ${selectedColor === "black" && "active"}`}
              onClick={() => handleColorButtonClick("black")}
            >
              Black
            </button>
            <button
              className={`btn btn-outline-danger btn-sm mx-2 ${selectedColor === "red" && "active"}`}
              onClick={() => handleColorButtonClick("red")}
            >
              Red
            </button>
            <button
              className={`btn btn-outline-light btn-sm mx-2 text-dark border border-dark ${
                selectedColor === "white" && "active"
              }`}
              onClick={() => handleColorButtonClick("white")}
            >
              White
            </button>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={closeModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Features;