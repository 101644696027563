import React, { useState } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import SpecificationBox from "./Specification-box";

const Process = ({ sectionClass }) => {
  const [state] = useState({
    services1: [
      {
        image: "assets/images/icons/icon6.png",
        title: "SIZE",
        desc: "Compact and sleek, Measuring 1840MM in length, 720MM IN Width, and 1130 in height.",
      },
      {
        image: "assets/images/icons/icon3.png",
        title: "POWER",
        desc: "Rated at 800W for an exhilaratinh riding experience.",
      },
      {
        image: "assets/images/icons/icon8.png",
        title: "VOLTAGE",
        desc: "Compatible with 60-72V power system.",
      },
    ],
    services2: [
      {
        image: "assets/images/icons/icon9.png",
        title: "SPEED",
        desc: "Reach a maximum speed of 55KM/H.",
      },
      {
        image: "assets/images/icons/icon4.png",
        title: "MOTOR TYPE",
        desc: "Equipped with a reliable permanent magnet motor.",
      },
      {
        image: "assets/images/icons/icon2.png",
        title: "BRAKES",
        desc: "Ensure safety with front disc and rear drum breaks.",
      },
    ],
    services3: [
      {
        image: "assets/images/icons/icon5.png",
        title: "TIRE SPECIFICATION",
        desc: "Front and rear tires with dimensions of 300-10.",
      },
      {
        image: "assets/images/icons/icon1.png",
        title: "WEIGHT",
        desc: "A lightweight design at 65KG [Net weight without battery] and 130KG [Net weight with battery] .",
      },
      {
        image: "assets/images/icons/icon7.png",
        title: "PLAYLOAD",
        desc: "Cable of carrying a maximum playload of upto 300KG .",
      },
    ],
  });

  return (
    <React.Fragment>
      <section className={"section " + sectionClass} id="services">
        <Container>
          {/* section title */}
          <SectionTitle
            title="Our Electric OR E Cooter"
            desc="Our Scooters are designed tp empower your daily commute and elevate your lifestyle. Discover the perfect blend of power, efficiency, and eco-fienliness as you embark on your journey with us."
          />

          <Row className="mt-4">
            {/* services box */}
            <SpecificationBox services={state.services1} />
          </Row>

          <Row className="mt-4">
            {/* service box */}
            <SpecificationBox services={state.services2} />
          </Row>
                    <Row className="mt-4">
            {/* service box */}
            <SpecificationBox services={state.services3} />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Process;
