import React from "react";
import { Link } from "react-router-dom";
export default function BlogContent() {
  return (
    <>
     <Link to="/blog"> <i className="fa fa-arrow-circle-left backButton" aria-hidden="true"></i></Link>
      <div
        className="d-flex flex-column "
        style={{ margin: "3rem 0rem 0rem 0rem" }}
      >
        
        <h2
          className="mainHead6"
          style={{
            margin: "auto",
            fontSize: "1.5rem",
            fontWeight: "bold",
            textAlign: "center"
          }}
        >
          Why Are Electric Bikes the Future of Pakistan?
        </h2>
        <li
          className="mainPara"
          style={{
            marginLeft: ".5rem",
            fontWeight: "bold",
            marginRight: ".5rem",
          }}
        >
          Save Money
        </li>
        <p
          className="mainPara"
          style={{
            marginLeft: ".5rem",
            textAlign: "justify",
            marginRight: ".5rem",
          }}
        >
          One of the most important reasons why electric motorcycles will
          dominate the local market is their less energy consumption. Some bikes
          consume around 1.5-2 units of electricity on a single charge and
          travel up to 80km – 100 km. at low-speed settings. The cost of 1.5 – 2
          units is around Rs.35 – Rs.45 in Pakistan. It means a person can
          travel up to 100 Km under Rs.50.For the same distance, many 70cc bikes
          with an average of 50-60 km/l consume around 1.7 – 2 litres of petrol.
          Petrol price in Pakistan is around Rs.220 per litre these days. So, a
          70cc bike can consume Rs.400 – Rs.450 petrol to travel 100
          Km.According to Pakistan Automotive Manufacturers Association (PAMA)
          2.3M bikes were sold in 2021 – 2022 alone. If Rs.5000 per month are
          saved by an electric bike on average, Pakistanis could save Rs.8.4
          Billion in one month and Rs.84.8 billion in one year. This is in
          addition to the emission reduction that comes with electric bikes.
        </p>
        <li
          className="mainPara"
          style={{
            marginLeft: ".5rem",
            fontWeight: "bold",
            marginRight: ".5rem",
          }}
        >
          Fewer Maintenance Issues
        </li>
        <p
          className="mainPara"
          style={{
            marginLeft: ".5rem",
            textAlign: "justify",
            marginRight: ".5rem",
          }}
        >
          The electric bike doesn’t have any engine, so it doesn’t require
          periodic maintenance such as engine oil change, tuning, or air filter
          change. There is no clutch, chain, or other parts that are associated
          with a petrol engine. So, there is no maintenance cost linked with
          these parts. On average, a person pays around Rs.2000 on every tuning
          (oil and air filter change + labour charges). This cost is eliminated
          with electric motorcycles. This is one of the ways you save money by
          owning an electric bike.
        </p>
        <li
          className="mainPara"
          style={{
            marginLeft: ".5rem",
            fontWeight: "bold",
            marginRight: ".5rem",
          }}
        >
          Electric Bikes Are Environment-friendly
        </li>
        <p
          className="mainPara"
          style={{
            marginLeft: ".5rem",
            textAlign: "justify",
            marginRight: ".5rem",
          }}
        >
          Due to emission of greenhouse gases in the air, our planet is heating
          up. If we want to continue living here, we need to switch to clean
          sources of energy. According to German watch, Pakistan is ranked in
          the list of top ten countries most affected by climate change in the
          past 20 years. Millions of combustion engine motorcycles are on the
          roads of Pakistan. Imagine the emission of CO2 and other toxic gasses
          from them. Electric bikes have no emissions as they run on
          electricity. Imagine if electric bikes capture over 50% of the market
          in Pakistan in the next 5 – 10 years. A huge amount of toxic emissions
          will be reduced.
        </p>
        <li
          className="mainPara"
          style={{
            marginLeft: ".5rem",
            fontWeight: "bold",
            marginRight: ".5rem",
          }}
        >
          An Electric Bike Is Safer
        </li>
        <p
          className="mainPara"
          style={{
            marginLeft: ".5rem",
            textAlign: "justify",
            marginRight: ".5rem",
          }}
        >
          Its speed can be set between 40 – 60 km/h. It means this motorcycle is
          suitable for traveling safely at slow speeds. Moreover, it is also
          safer for young riders to learn to drive a bike at fixed speeds.
        </p>
        <li
          className="mainPara"
          style={{
            marginLeft: ".5rem",
            fontWeight: "bold",
            marginRight: ".5rem",
          }}
        >
          Electric Bikes Are Quieter (Vibration Less)
        </li>
        <p
          className="mainPara"
          style={{
            marginLeft: ".5rem",
            textAlign: "justify",
            marginRight: ".5rem",
          }}
        >
          It is common for traditional bikes to have vibration. Vibration is
          produced due to the engine. As the electric motorcycles don’t have an
          engine, they are almost vibration less and quieter. It means these
          bikes are not only good for eliminating atmospheric pollution but also
          noise pollution. Their drive is much more comfortable than the
          combustion engine motorcycles.
        </p>
        <li
          className="mainPara"
          style={{
            marginLeft: ".5rem",
            fontWeight: "bold",
            marginRight: ".5rem",
          }}
        >
          Electric Bikes Are for Everyone
        </li>
        <p
          className="mainPara"
          style={{
            marginLeft: ".5rem",
            textAlign: "justify",
            marginRight: ".5rem",
          }}
        >
          Many people think that electric motorcycles are just for low-income
          riders. They will indeed benefit the low to medium-income riders.
          However, many companies are producing electric sports bikes
          internationally. It means high-income people might also get electric
          heavy bikes in Pakistan in future.
        </p>
      </div>
    </>
  );
}
