import React from "react";
import { Link } from "react-router-dom";
export default function OverComeHurdles() {
  return (
    <>
     <Link to="/blog"> <i className="fa fa-arrow-circle-left backButton" aria-hidden="true"></i></Link>
    <div className=" "  style={{ margin: "3rem 0rem 0rem 0rem" }}>
       
      <h1
        style={{
          color: "black",
          fontWeight: "bold",
          fontSize: "1.5rem",
          textAlign: "justify",
          
        }}
      >
        Overcoming Transportation Hurdles for Women in the Workforce: A Youth
        Population Study 2018-2022:
      </h1>
      <div className="table-responsive">
      <table
        className="table table-dark w-100"
        style={{ border: "0.06rem solid gray" }}
      >
        <thead>
          <tr style={{ border: "0.06rem solid gray" }}>
            <th style={{ border: "0.06rem solid gray" }} scope="col">
              Year
            </th>
            <th style={{ border: "0.06rem solid gray" }} scope="col">
              Total Youth Population (15- 29 years)
            </th>
            <th style={{ border: "0.06rem solid gray" }} scope="col">
              Percentage of Women in the Workforce
            </th>
            <th style={{ border: "0.06rem solid gray" }} scope="col">
              Reasons for Hurdles in Women&#39;s Employment
            </th>
            <th style={{ border: "0.06rem solid gray" }}>
              Transportation- Related Hurdles
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th style={{ border: "0.06rem solid gray" }} scope="row">
              2022
            </th>
            <td style={{ border: "0.06rem solid gray" }}>33.76 million</td>
            <td style={{ border: "0.06rem solid gray" }}>23.8%</td>
            <td style={{ border: "0.06rem solid gray" }}>
              Patriarchal attitudes, lack of opportunities, gender
              discrimination
            </td>
            <td style={{ border: "0.06rem solid gray" }}>
              Lack of safe and reliable transportation options, limited access
              to public transportation, cultural barriers to women&#39;s
              mobility
            </td>
          </tr>
          <tr>
            <th style={{ border: "0.06rem solid gray" }} scope="row">
              2021
            </th>
            <td style={{ border: "0.06rem solid gray" }}>33.29 million</td>
            <td style={{ border: "0.06rem solid gray" }}>23.5%</td>
            <td style={{ border: "0.06rem solid gray" }}>
              Limited access to education and skills, social norms and gender
              roles, lack of support from family and community
            </td>
            <td style={{ border: "0.06rem solid gray" }}>
              Unsafe and uncomfortable transportation options, harassment and
              violence on public transportation, limited availability of
              transportation at certain times or locations
            </td>
          </tr>
          <tr>
            <th style={{ border: "0.06rem solid gray" }} scope="row">
              2020
            </th>
            <td style={{ border: "0.06rem solid gray" }}>32.82 million</td>
            <td style={{ border: "0.06rem solid gray" }}>23.2%</td>
            <td style={{ border: "0.06rem solid gray" }}>
              Economic instability, lack of job security and benefits, gender
              discrimination
            </td>
            <td style={{ border: "0.06rem solid gray" }}>
              Limited transportation options in rural areas, cost and
              affordability of transportation, lack of safe transportation for
              women at night
            </td>
          </tr>
          <tr>
            <th style={{ border: "0.06rem solid gray" }} scope="row">
              2019
            </th>
            <td style={{ border: "0.06rem solid gray" }}>32.36 million</td>
            <td style={{ border: "0.06rem solid gray" }}>22.9%</td>
            <td style={{ border: "0.06rem solid gray" }}>
              Lack of training and skills, social norms and cultural beliefs,
              limited job opportunities in certain sectors
            </td>
            <td style={{ border: "0.06rem solid gray" }}>
              Limited access to transportation in remote areas, lack of public
              transportation infrastructure, inadequate safety measures on
              public transportation
            </td>
          </tr>
          <tr>
            <th style={{ border: "0.06rem solid gray" }} scope="row">
              2018
            </th>
            <td style={{ border: "0.06rem solid gray" }}>31.90 million</td>
            <td style={{ border: "0.06rem solid gray" }}>22.6%</td>
            <td style={{ border: "0.06rem solid gray" }}>
              Lack of support from family and community, limited access to
              education and training, gender discrimination
            </td>
            <td style={{ border: "0.06rem solid gray" }}>
              Limited transportation options for women in conservative areas,
              lack of transportation options for women with disabilities,
              inadequate safety measures on public transportation
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>
    </>
  );
}
