import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const Social = () => {
  const phoneNumber = '+923105099158';
  const emailAddress = 'contact@risingirls.com';

  const openWhatsApp = () => {
    const message = 'Hi there! I have a question.';
    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, '_blank');
  };

  const openEmail = () => {
    const subject = 'Inquiry';
    const emailURL = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}`;
    window.location.href = emailURL;
  };

  return (
    <React.Fragment>
      <section className="contact-social bg-light">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <ul className="list-inline social mt-4">
                <li className="list-inline-item">
                  <Link to="https://www.facebook.com/profile.php?id=61552439624195&mibextid=LQQJ4d" className="social-icon">
                    <i className="mdi mdi-facebook" style={{ color: "#1877F2" }}></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="https://x.com/risingirls?s=21" className="social-icon">
                    <i className="mdi mdi-twitter" style={{ color: "#1DA1F2" }}></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="https://www.linkedin.com/company/risingirls/" className="social-icon">
                    <i className="mdi mdi-linkedin" style={{ color: "#0A66C2" }}></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="https://instagram.com/risinggirls_?igshid=cmI5YzBoaHNldjBk&utm_source=qr" className="social-icon">
                    <i className="mdi mdi-instagram" style={{
                      background: "linear-gradient(to top, #E1306C, #FCAF45)",
                      WebkitBackgroundClip: "text",
                      backgroundClip: "text",
                      color: "transparent"
                    }}></i>
                  </Link>
                </li>
              </ul>
            </Col>
            <Col lg={6} className="mt-4 d-flex flex-wrap justify-content-between">
              <a className="contact-title " href={`tel:${phoneNumber}`}>
                <i className="pe-7s-call"></i> &nbsp;{phoneNumber}
              </a>
              <a className="contact-title ms-0 ms-lg-2" href={`mailto:${emailAddress}`}>
                <i className="pe-7s-mail-open"></i>&nbsp;{emailAddress}
              </a>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Social;
