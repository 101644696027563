import React from "react";
import { Container, Row, Col, Form, Button } from "reactstrap";

const Section = () => {
  return (
    <React.Fragment>
      <section className="section bg-home home-half" id="home">
        <div className="bg-overlay"></div>
        <div className="display-table">
          <div className="home-cell-bottom">
            <Container>
              <Row>
                <Col
                  lg={{ size: 8, offset: 2 }}
                  className="text-white text-center"
                >
                <h1 className="home-title">
                    EMPOWER MOVE EMPOWER YOU.
                  </h1>
                  <p className="pt-3 home-desc">
                    WELCOME TO THE FUTURE OF URBAN TRANSPORTATION WITH SAK EVS & TECHNOLOGIES' ELECTRIC SCOOTERS.
                  </p>
                  {/* <div className="text-center subscribe-form mt-5">
                    <Form>
                      <input type="text" placeholder="Email" required />
                      <Button
                        color="none"
                        type="submit"
                        className="btn-primary"
                      >
                        Subscribe
                      </Button>
                    </Form> 
                  </div>*/}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Section;
