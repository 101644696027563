import React, { useState } from "react";
import { Row } from 'reactstrap';
import { Container, Modal, ModalBody, ModalHeader, Button,Col } from "reactstrap";
import SectionTitle from "../common/section-title";
import BlogBox from "./BlogBox";
import BlogContent from "./BlogContent";
import OvercomeHurdles from "./OverComeHurdles";
import ClimateBenefits from "./ClimateBenefits";
import OpandChallenges from "./OpandChallenges";
import Erevolution from "./Erevolution";
import Ebikes from "./Ebikes";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Blog = () => {

  const [openedBlogIndex, setOpenedBlogIndex] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [viewAllClicked, setViewAllClicked] = useState(false);
  const blogs = [
    {
      image: "assets/images/blog/blog1.webp",
      title: "Electric Bikes are the Future of Pakistan",
      desc: "One of the most important reasons why electric motorcycles will dominate..",
      content: <BlogContent />,
      isContentOpen: false,
    },
    {
      image: "assets/images/blog/blog2.webp",
      title: "Overcome Transportation Hurdles",
      desc: "Overcoming Transportation Hurdles for Women in the Workforce: A Youth Population Study 2018-2022..",
      content: <OvercomeHurdles />,
      isContentOpen: false,
    },
    {
      image: "assets/images/blog/blog3.webp",
      title: "The Climate Benefits of Electric Bikes",
      desc: "As the world becomes more environmentally conscious..",
      content: <ClimateBenefits />,
      isContentOpen: false,
    },
    {
      image: "assets/images/blog/blog4.webp",
      title: "E-Bikes as girl's Preferred Mode of Transportation",
      desc: "In Pakistan, the use of e-bikes is gaining popularity..",
      content: <Ebikes />,
      isContentOpen: false,
    },
    {
      image: "assets/images/blog/blog5.webp",
      title: <div dangerouslySetInnerHTML={{__html: '&quot;E-Bike Revolution: Promoting Sustainable Transportation in Pakistan&quot;'}} />,
      desc: "In recent years, Pakistan has experienced a transportation..",
      content: <Erevolution />,
      isContentOpen: false,
    },
    {
      image: "assets/images/blog/blog6.webp",
      title: "What the Future Holds for E-Bikes in Pakistan: Opportunities and Challenges",
      desc: "Electric bikes, or e-bikes, are gaining popularity in Pakistan..",
      content: <OpandChallenges />,
      isContentOpen: false,
    },
  ];
  
  const openBlogContent = (index) => {
    setOpenedBlogIndex(index);
    setModalIsOpen(true);
  };

  const closeBlogContent = () => {
    setOpenedBlogIndex(null);
    setModalIsOpen(false);
  };

  const viewAllBlogs = () => {
    setViewAllClicked(true);
    // Scroll to the blog section when clicking "View All"
    const blogSection = document.getElementById("blog");
    blogSection.scrollIntoView({ behavior: "smooth" });
  };
  
  const closeAllBlogs = () => {
    setViewAllClicked(false);
    // Scroll to the blog section when closing the view
    const blogSection = document.getElementById("blog");
    blogSection.scrollIntoView({ behavior: "smooth" });
  };
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // For large devices
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2, // For medium devices
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1, // For small devices
        },
      },
    ],
  };

  const imageSlideStyles = `
    .image-slider-container .slick-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .image-slider-container .slick-list {
      overflow: hidden;
    }

    .image-slider-container img {
      width: 100%;
      height: auto;
    }

    .slick-prev, .slick-next {
      z-index: 1;
      top: 35%; 
    }

    .slick-prev {
      left: 20px; 
    }

    .slick-next {
      right: 40px; 
    }

    .slick-prev:before, .slick-next:before {
      font-size: 40px; 
    }
    .modal-dialog {
      max-width: 80% !important; 
    }
    
  `;

  return (
    <section className="section bg-light pt-5" id="blog">
      <Container>
        <SectionTitle
          title="BLOG"
          desc="Explore insightful articles and stories that cover a wide range of topics. Stay informed and inspired with our latest blog posts."
        />
  {viewAllClicked ? (
  <Row>
    {blogs.map((blog, index) => (
      <Col key={index} md={4}>
        <BlogBox
          blog={blog}
          openBlogContent={() => openBlogContent(index)}
          closeBlogContent={closeBlogContent}
        />
      </Col>
    ))}
    <div className="text-center"style={{ marginTop: "40px" }}>
      <Button color="primary" onClick={closeAllBlogs}>
        Close
      </Button>
    </div>
  </Row>
) : (

          <>
            <Slider {...sliderSettings}>
              {blogs.map((blog, index) => (
                <BlogBox
                  key={index}
                  blog={blog}
                  openBlogContent={() => openBlogContent(index)}
                  closeBlogContent={closeBlogContent}
                />
              ))}
            </Slider>
            <div className="text-center " style={{marginTop:"100px"}}>
              <Button color="primary" onClick={viewAllBlogs}>
                View All
              </Button>
            </div>
          </>
        )}

        <Modal isOpen={modalIsOpen} toggle={closeBlogContent} size="lg mx-auto">
          {openedBlogIndex !== null && (
            <>
              <ModalHeader toggle={closeBlogContent}>
                {blogs[openedBlogIndex].title}
              </ModalHeader>
              <ModalBody>{blogs[openedBlogIndex].content}</ModalBody>
            </>
          )}
        </Modal>

        <style>{imageSlideStyles}</style>
      </Container>
    </section>
  );
};

export default Blog;