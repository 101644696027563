import React from "react";
import routes from "./routes";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <React.Fragment>
      <Routes>
        {routes.map((route, idx) => (
          <Route path={route.path} element={route.component} key={idx} />
        ))}
      </Routes>
      
      <a
          className="btn-fill btn-blue sanbg"
          href="https://api.whatsapp.com/send?phone=+923105099158&text=Hi%20there!%20I%20have%20a%20question%20:)
          "
          target="_blank"
          rel="noreferrer"
        >
          <img
          src="assets/images/whatsApp.webp"
            style={{
              width: "4rem",
              position: "fixed",
              right: "5px",
              bottom: "1rem",
              zIndex: "10",
            }}
            alt="whatsapp"
          />
        </a>
    </React.Fragment>
  );
}

export default App;
