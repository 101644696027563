

import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const AboutUs = () => {
  const [showMore, setShowMore] = useState(false);

  const toggleMore = () => {
    setShowMore(true);
  };

  const toggleLess = () => {
    setShowMore(false);
  };

  return (
    <React.Fragment>
      <section className="section bg-light" id="features">
        <Container>
          <Row className="vertical-content" >
            <Col lg={5}>
              <div className="features-box text-fluid" >
                <h3>Girl's E-Bikes and the Environment:</h3>
                <p className="text-muted text-justify" >
                  The use of girl's e-bikes can have a positive impact on the
                  environment. Here are some of the ways in which girl's e-bikes
                  can help reduce environmental impact:
                </p>
                <h4 >
                  Reduced Carbon Emissions
                </h4>
                <p className="text-muted text-justify">
                  The use of girls' e-bikes can help reduce carbon emissions.
                  Traditional cars emit a significant amount of carbon dioxide,
                  which contributes to climate change. Girls' e-bikes, on the
                  other hand, do not emit any harmful pollutants into the
                  environment, making them a great alternative to traditional
                  cars.
                </p>
                {showMore && (
                  <React.Fragment>
                    <h4  >
                      Reduced Traffic Congestion
                    </h4>
                    <p className="text-muted text-justify " >
                      Girls' e-bikes can help reduce traffic congestion,
                      particularly in urban areas. As more people switch to
                      e-bikes, there will be fewer cars on the road, reducing
                      the overall traffic volume and improving air quality.
                    </p>
                    <h4  >
                      Reduced Noise Pollution
                    </h4>
                    <p className="text-muted  text-justify" >
                      Girls' e-bikes are much quieter than traditional cars and
                      motorcycles, reducing noise pollution in urban areas. This
                      makes them a great option for customers who want to reduce
                      their environmental impact while still enjoying a
                      comfortable and enjoyable ride.
                    </p>
                  </React.Fragment>
                )}

                <button
                  id="moreButton"
                  className="btn btn-primary mt-4 waves-effect waves-light"
                  onClick={toggleMore}
                  style={!showMore ? { display: "block" } : { display: "none" }}
                >
                  see more
                </button>
                <button
                  id="lessButton"
                  className="btn btn-primary mt-4 waves-effect waves-light"
                  onClick={toggleLess}
                  style={showMore ? { display: "block" } : { display: "none" }}
                >
                  see less
                </button>
              </div>
            </Col>
            <Col lg={7}>
              <div className="features-img features-right text-end">
                <img
                  src="assets/images/logo.webp"
                  alt="macbook"
                  className="img-fluid"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default AboutUs;
