// BlogBox.js
import React from "react";
import { Col, Button } from "reactstrap";

const BlogBox = ({ blog, openBlogContent }) => {
  return (
    <Col>
      <style>
        {`

          .blog-title {
            max-width: 100%; 
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .blog-desc {
            max-width: 100%; 
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            height:2.7rem;
            -webkit-box-orient: vertical;
          }
        `}
      </style>

      <div className="blog-box mt-4 mx-3 hover-effect">
        <img
          src={blog.image}
          className="img-fluid"
          alt="blog"
          style={{ height: "200px", width: "100%", objectFit: "cover" }}
        />
        <div className="blog-content">
          <h4 className="mt-3 blog-title">
            <a
              href="#"
              className="blog-title"
              onClick={(e) => {
                e.preventDefault();
                openBlogContent();
              }}
            >
              {blog.title}
            </a>
          </h4>
          <div className="blog-desc">
            <p className="text-muted">{blog.desc}</p>
          </div>
          <div className="mt-3">
            <Button color="primary" onClick={openBlogContent}>
              Read More
            </Button>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default BlogBox;
