import React from "react";
import { Col } from "reactstrap";

const ProductBox = ({ clients, openClientContent }) => {
  return (
    <React.Fragment>
      {clients.map((client, index) => (
        <Col key={index} lg={5} md={6} sm={12}>
          <div className="team-box text-center hover-effect" style={{ backgroundColor: "#E9EAE4", width: "100%" }}>
            <div className="team-wrapper">
              <div className="team-member">
                <img alt="team" src={client.image} className="img-fluid" style={{ width: "100%" }} />
              </div>
            </div>

            <h4 className="team-name">{client.title}</h4>
            <a
              href="#"
              className="read-btn"
              onClick={(e) => {
                e.preventDefault();
                openClientContent(index);
              }}
            >
              Learn More
            </a>
          </div>
        </Col>
      ))}
    </React.Fragment>
  );
};

export default ProductBox;
